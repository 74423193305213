import hexToRgba from 'hex-to-rgba';

export function isEmpty(item) {
  return (
    item === undefined ||
    item === '' ||
    (Array.isArray(item) && item.every(isEmpty)) ||
    (typeof item === "object" && (item === null || Object.keys(item).length === 0))
  );
}

export function extractEmailAddressDomain(email) {
  //This function isn't great but does the job for our purposes
  var splitEmail = email.split('@');
  return splitEmail[1];
}

export function capitalizeFirstLetter(string) {
  return (string ? (string[0].toUpperCase() + string.substring(1)) : string);
}

export function htmlStringToDisplay(string) {
  var words = (string ? string.split('-') : []);

  words[0] = words[0][0].toUpperCase() + words[0].substr(1);

  return words.join(' ');
}

export function toHTMLString(string) {
  return string ? string.replace(' ','-').toLowerCase() : string;
}

export function formatTemperature(number) {
  return number + '°F';
}

//Helper to display large numbers with commas
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function displayPercent(x) {
  return Math.round(parseFloat(x*100))+"%";
}

export function displaySolarPower(solarQuantity, solar) {
  return (
    solar ?
    (
      solar.sizeWatt ?
      `${Math.round(solarQuantity * solar.sizeWatt * .001)} kW` :
      `${Math.round(solarQuantity * solar.value)} kW`
    ):
    'Select Solar'
  );
}

export function displayInverterPower(inverterQuantity, inverter) {
  return (
    inverter ?
    (
      inverter.gridNameplateCapacityKw ?
      `${Math.round(inverterQuantity*inverter.gridNameplateCapacityKw)} kW` :
      `${Math.round(inverterQuantity*inverter.value)} kW`
    ) :
    'Select Inverter'
  );
}

export function displayBatteryCapacity(formData, battery, maxDailyConsumption) {
  let batteryCapacity = battery ?
    (
      battery.capacityKwh ?
      Math.round(formData.batteryQuantity * battery.capacityKwh) :
      Math.round(formData.batteryQuantity * battery.value)
    ) : '';
  let powerLabel = batteryCapacity ?
    batteryCapacity + 'kWh' :
    'Select Battery';
  let consumptionPercentageLabel = maxDailyConsumption ?
    ' - ' + Math.round(100 * batteryCapacity / (maxDailyConsumption / 24)) + '% of max hourly usage' :
    ''
  return powerLabel + consumptionPercentageLabel;
}

export function getMatchingRefs(refs, categoryLabel) {
  return (refs ? refs.filter(ref => ref.category.label === categoryLabel) : []);
}

export const formatAsCurrency = (value) => {
  if (typeof value === "number") {
    return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(value);
  } else {
    return value;
  }
};

export const hexToRGBAString = (hexColor) => {
  //example output: 'rgba(239,179,7,1)'
  return hexToRgba(hexColor);
}

export const rgbaStringToHex = (rgbaString) => {
  //example input: 'rgba(239,179,7,1)'
  const splitString = rgbaString.substring(rgbaString.indexOf("(") + 1, rgbaString.indexOf(")")).split(",");
  let red = (+splitString[0]).toString(16);               
  let green = (+splitString[1]).toString(16);
  let blue = (+splitString[2]).toString(16);
  let alpha = Math.round(+splitString[3] * 255).toString(16);

  if (red.length === 1) {
    red = "0" + red;
  }
  if (green.length === 1) {
    green = "0" + green;
  }
  if (blue.length === 1) {
    blue = "0" + blue;
  }
  if (alpha.length === 1) {
    alpha = "0" + alpha;
  }
  return "#" + red + green + blue + alpha;
}