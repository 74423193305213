import React, { useState, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import DropdownInput from 'components/inputs/dropdown.jsx';
import SliderInput from 'components/inputs/slider.jsx';
import SingleCheckbox from 'components/inputs/single-checkbox.jsx';
import Col from 'react-bootstrap/esm/Col.js';
import { REF_KEYS } from 'utils/constants.js';
import { getMatchingRefs, displaySolarPower } from '../../utils/lib.js';
import useDeviceSize from 'hooks/device-size';

function OptionalSolarWidget({
  formData,
  setFieldValue,
  refs,
  customEmbedConfig
}) {
  const { isMobile } = useDeviceSize();
  const { makeSolarOptional, defaultSolarModule } = customEmbedConfig;
  const [useSolar, setUseSolar] = useState(makeSolarOptional ? false : true);
  const handleUseSolarToggle = (newValue) => {
    setUseSolar(newValue);
    setFieldValue("solarQuantity",newValue ? defaultSolarModule.quantity : 0);
  }
  const { selectedSolarModule } = formData;
  const solarOptions = getMatchingRefs(refs, REF_KEYS.BasicSolarOptions);
  const solarPowerDisplay = useMemo(
    () => displaySolarPower(formData.solarQuantity,selectedSolarModule),
    [formData.solarQuantity, selectedSolarModule]
  );
  const maxSolar = 96;
  const dropdownColWidth = isMobile ? 0 : 3;
  const sliderColWidth = 12 - dropdownColWidth;
  const baseStyle = {
    marginTop: '11px',
    marginBottom: '22px'
  }
  return (
    < div >
      <Row>
        <Col xs={isMobile ? 12 : 6} />
        <Col style={{ paddingLeft: "24px" }} xs={isMobile ? 12 : 6}>
          <SingleCheckbox
            id="add-solar-checkbox"
            label="System Includes Solar"
            checked={useSolar}
            onChange={(newValue) => handleUseSolarToggle(newValue)}
            tooltip="Select this option to add solar equipment to your design"
          />
        </Col>
      </Row>

      {
        useSolar && (
          <Row>
            <DropdownInput
              style={baseStyle}
              displayName="Solar Module Size"
              htmlName="solar-module-size"
              fieldName="solarModule"
              tooltip="Solar modules come in a wide range of power ratings. Consider selecting a more powerful model if your home lacks south-facing roof space."
              value={formData.solarModule}
              options={solarOptions}
              formData={formData}
              setFieldValue={setFieldValue}
              colWidth={dropdownColWidth}
              showWhen={!isMobile}
            />
            <SliderInput
              style={baseStyle}
              htmlName="solar-slider"
              displayName="Total Solar Power"
              fieldName={"solarQuantity"}
              value={formData.solarQuantity}
              max={maxSolar}
              sliderLabelSingle="module"
              sliderLabelPlural="modules"
              displayLabel={solarPowerDisplay}
              setFieldValue={setFieldValue}
              colWidth={sliderColWidth}
              isMobile={isMobile}
            />
          </Row>
        )
      }
    </div >
  )
}

export default OptionalSolarWidget;