import { defaultFreeDesign } from "components/objects/defaults";

export const EMBED_CONFIG_OPTIONS = [
  { name: "showBackButton", label: "Show Back Button"},
  { name: "makeSolarOptional", label: "Make Solar Optional"},
  { name: "skipRateSelectionPage", label: "Skip Rate Selection Page" },
  { name: "hideSystemDesignSection", label: "Hide System Design Section" },
  { name: "hideEstimatesSection", label: "Hide Estimates Section" },
  { name: "hideBillInfoSection", label: "Hide Bill Info Section" },
  {
    name: "hideRateSelectionResultsPage",
    label: "Hide Rate Selection Results Page",
  },
  {
    name: "hideElectricBillAndRateValuesResultsPage",
    label: "Hide Electric Bill and Rate Values Results Page",
  },
  { name: "hideKwhUseResultsPage", label: "Hide kWh Use Results Page" },
  { name: "hidePerformanceChart", label: "Hide Performance Chart" },
  { name: "hideSystemDetailsChart", label: "Hide System Details Chart" },
];

export const EMBED_CONFIG_DEFAULTS = {
  embedConfig: {
    signupTermsOfUse: 'By clicking “Email Report,” I consent to PVBatts providing me information on PVBatts products. Click unsubscribe in any email to be removed from email contact. Consent is not a condition of purchase. I understand this report is an estimate only and does not guarantee a final system price or economics. This report is not an installation quote. Please see our Privacy Statement and Terms of Use.',
    defaultMapAddress: "City Hall, Philadelphia, PA 19107, USA",
    showBackButton: false,
    makeSolarOptional: false,
    skipRateSelectionPage: false,
    hideSystemDesignSection: false,
    hideEstimatesSection: false,
    hideBillInfoSection: false,
    hideRateSelectionResultsPage: false,
    hideElectricBillAndRateValuesResultsPage: false,
    hideKwhUseResultsPage: false,
    hidePerformanceChart: false,
    hideSystemDetailsChart: false,
    defaultSolarModule: {
      quantity: defaultFreeDesign.solarQuantity,
      value: .41,
    },
    defaultBattery: {
      quantity: defaultFreeDesign.batteryQuantity,
      value: 5.12,
    },
    positivePieChartColorA: "rgba(239,179,7,1)",
    positivePieChartColorB: "rgba(0,162,232,1)",
    negativePieChartColor: "rgba(67,253,242,1)"
  },
}
