import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CurrencyInput from 'react-currency-input-field';
import useDeviceSize from 'hooks/device-size';
import { questionMarkIcon } from '../../../components/objects/icons.jsx';

function MonthlyConsumptionInput({
  formData,
  setFieldValue,
  readOnly,
  showLabel
}) {
  const setMonthConsumptionData = (index, value) => {
    setFieldValue("siteConsumptionData", [
      ...formData.siteConsumptionData.slice(0, index),
      value,
      ...formData.siteConsumptionData.slice(index + 1),
    ]);
  };

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const monthSets = [
    ['January', 'February'],
    ['March', 'April'],
    ['May', 'June'],
    ['July', 'August'],
    ['September', 'October'],
    ['November', 'December']
  ];

  const renderTooltip = props => (
    <Tooltip {...props}> Based on your current energy bill, a default value has been set. You can change these values with exact information from your energy bill. </Tooltip>
  );

  const { isDesktop } = useDeviceSize();
  return (
    <Container>
      {showLabel && (
        <Row>
          {
            readOnly ?
            (
              <div>
                <p className='sub-header'>
                  Monthly electricity consumption (kWh):
                </p>
              </div>
            ) :
            (
              <div>
                <p className='instructions'>
                  Enter Monthly kWh (optional)
                  <span>
                  {
                    readOnly !== true ?
                    <div
                      style={{
                        display: 'inline',
                        marginLeft: '6px',
                      }}>
                        <OverlayTrigger placement="right" overlay={renderTooltip}>
                          { questionMarkIcon }
                        </OverlayTrigger>
                    </div> :
                    <div/>
                  }
                </span>
                </p>
              </div>
            )
          }
        </Row>
      )}
      {
        isDesktop ?
          <Row>
            {
              months.map((month, index) =>
                <Col 
                  md={1}
                  key={`${month}-column`}>
                  <label
                    id={`${month.toLowerCase()}-consumption-label`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    htmlFor={`${month.toLowerCase()}-consumption`}> { month}
                  </label>
                  <CurrencyInput
                    style={{
                      fontSize: '14px',
                      display: 'block',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                    id={`${month.toLowerCase()}-consumption`}
                    className='form-control'
                    placeholder='kWh'
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => e.target.value ? {} : setMonthConsumptionData(index, 0)}
                    value={isNaN(formData.siteConsumptionData[index]) ? 0 : formData.siteConsumptionData[index]}
                    decimalsLimit={2}
                    allowNegativeValue={false}
                    onValueChange={value => setMonthConsumptionData(index, isNaN(value) ? 0 : Number(value))}
                  />
                </Col>
              )
            }
          </Row> :
          monthSets.map((monthSet, monthSetIndex) => (
            <Row 
              key={`consumption-month-row-${monthSetIndex}`}
              className={'justify-content-center'}>
              {
                monthSet.map((month, monthIndex) => {
                  const dataIndex = (monthSetIndex * 2) + monthIndex;
                  
                  return (
                    <Col
                      xs={6}
                      key={`${month}-column`}>
                      <label
                        id={`${month.toLowerCase()}-consumption-label`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        htmlFor={`${month.toLowerCase()}-consumption`}> { month}
                      </label>
                      <CurrencyInput
                        style={{
                          fontSize: '14px',
                          display: 'block',
                          alignItems: 'center',
                          textAlign: 'center',
                          width: '100%'
                        }}
                        placeholder='kWh'
                        onFocus={() => setMonthConsumptionData(dataIndex, '')}
                        onBlur={(e) => e.target.value ? {} : setMonthConsumptionData(dataIndex, 0)}
                        value={isNaN(formData.siteConsumptionData[dataIndex]) ? '' : formData.siteConsumptionData[dataIndex]}
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        onValueChange={value => setMonthConsumptionData(dataIndex, isNaN(value) ? 0 : Number(value))}/>
                    </Col>
                  )
                })
              }
            </Row>
          ))
      }
    </Container>
  );
}

export default MonthlyConsumptionInput;