import React, { useState, useRef, useEffect, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import PieChart from 'components/widgets/pie-chart.jsx';
import ResultsTable from 'components/widgets/results-table.jsx';
import useDeviceSize from 'hooks/device-size.jsx';
import GeneralButton from 'components/buttons/general-button.jsx';
import GeneralSubmitButton from 'components/buttons/general-submit-button.jsx';
import { slidersIcon } from 'components/objects/icons.jsx';
import SystemCost from './system-cost/system-cost-widget.jsx';
import { getUpdatedSystemCostValues } from './system-cost/system-cost-utils.js';
import MonthlyConsumptionInput from '../monthly-consumption.jsx'
import MailingListSignup from './sign-up/sign-up.jsx';
import QuickDesignSystemSummary from './system-summary.jsx';
import BillInfo from './bill-info/bill-info-widget.jsx';
import { returnQuickDesignResultRows } from 'utils/data.js';
import { fetchAndDownloadCsv, getGridRowData, getPieChartData } from './results-utils.js';
import useApi from 'hooks/api.js';
import SystemDesign from '../system-design.jsx';
import { useParams } from 'react-router-dom';
import { useRefs } from 'contexts/refs-context.jsx';
import { useUserContext } from 'contexts/user-context.jsx';

function QuickDesignResults({
  resultsGridRef,
  formData,
  setFieldValue,
  goToPreviousStep,
  rateStructures,
  pvWattsData,
  customEmbedConfig,
  setPVWattsData
}) {
  const { refs } = useRefs();
  const emailReportRef = useRef();
  const { user } = useUserContext();

  const [widgetVisibility, setWidgetVisibility] = useState({
    showDesignWidget: customEmbedConfig.makeSolarOptional,
    showEmailWidget: false,
    showSystemCostWidget: false,
    showBillInfoWidget: false,
    showKwhUse: false,
  });
  const [generatingEmailReport, setGeneratingEmailReport] = useState(false);
  const [advancedGridRowData, setAdvancedGridRowData] = useState(null);
  const [cache, setCache] = useState({});
  const { isMobile } = useDeviceSize();
  const  api = useApi();
  const { teamCode: usingCustomEmbed } = useParams();

  const {
    showBackButton,
    makeSolarOptional,
    hideSystemDesignSection,
    hideEstimatesSection,
    hideBillInfoSection,
    hideRateSelectionResultsPage,
    hideKwhUseResultsPage,
    hidePerformanceChart,
    hideSystemDetailsChart,
    signupTermsOfUse,
    positivePieChartColorA,
    positivePieChartColorB,
    negativePieChartColor
  } = customEmbedConfig;

  const toggleWidget = (widgetName) => {
    setWidgetVisibility((prevState) => ({
      ...prevState,
      [widgetName]: !prevState[widgetName],
    }));
  };

  const {
    touRateSelected,
    selectedBattery,
    selectedInverters,
    selectedSolarModule
  } = formData;

  const quickDesignRowData = useMemo(
    () =>
      returnQuickDesignResultRows(
        formData,
        advancedGridRowData,
        selectedBattery
      ),
    [formData, advancedGridRowData, selectedBattery]
  );

  const pieChartData = useMemo(
    () => getPieChartData(advancedGridRowData, quickDesignRowData, formData, rateStructures, makeSolarOptional),
    [formData, advancedGridRowData, quickDesignRowData]
  );

  useEffect(() => {
    let isMounted = true;

    const cacheKey = JSON.stringify({
      refs,
      formData,
      selectedSolarModule,
      selectedInverters,
      selectedBattery,
      pvWattsData,
      rateStructures,
    });

    if (cache[cacheKey]) {
      setAdvancedGridRowData(cache[cacheKey]);
    } else {
      getGridRowData(
        refs,
        formData,
        selectedSolarModule,
        selectedInverters,
        selectedBattery,
        pvWattsData,
        rateStructures,
        api
      ).then((data) => {
        if (isMounted) {
          setAdvancedGridRowData(data);
          setCache((prevCache) => ({ ...prevCache, [cacheKey]: data }));
        }
      }).catch((error) => {

      });
    }

    return () => {
      isMounted = false;
    };
  }, [
    refs,
    formData.selectedBattery,
    formData.selectedInverters,
    formData.selectedSolarModule,
    formData.newRateStructure,
    formData.gridChargeDisabled,
    formData.netMeteringEnabled,
    formData.batteryQuantity,
    formData.solarQuantity,
    formData.inverterQuantity,
    formData.oldRateStructure,
    formData.siteConsumptionData,
    pvWattsData,
    rateStructures,
  ]);

  // TODO: Refactor to set this as state. Was running into infinite rerenders.
  // This isn't causing problems yet but it very well may in the future.
  const systemCostValuesToUpdate = React.useMemo(
    () => getUpdatedSystemCostValues(formData, selectedBattery, selectedSolarModule),
    [formData, selectedBattery, selectedSolarModule]
  );
  formData = { ...formData, ...systemCostValuesToUpdate };

  const handleDownloadCsv = async () => {
    const batteryStartCharge =
      formData.batteryQuantity *
      selectedBattery.capacityKwh *
      0.5;

    await fetchAndDownloadCsv(
      refs,
      formData,
      selectedSolarModule,
      selectedInverters,
      selectedBattery,
      rateStructures,
      pvWattsData,
      batteryStartCharge,
      api
    );
  };

  return (
    <div>
      <Col xl={4}>
        {showBackButton && (
          <GeneralButton
            text="Go Back"
            variant="secondary"
            onClick={goToPreviousStep}
            fullWidth
            rounded
          />
        )}
      </Col>
      <div ref={emailReportRef} className="EmailReport">
        <div style={{ height: "16px" }} />
        {!usingCustomEmbed && (
          <Row className="align-items-center">
            <h2
              style={{
                fontWeight: "bold",
                textAlign: "center",
                textDecoration: "underline",
                textUnderlinePosition: "under",
              }}
            >
              {"PVBatts System Report"}
            </h2>
          </Row>
        )}
        <div className="padding" />
        <div className="padding" />
        <Container style={{ marginBottom: "16px" }}>
          {isMobile ?
          pieChartData.length > 3 ? (
            <div>
              <Stack
                className="justify-content-center"
                direction="horizontal"
                gap={5}
              >
                {pieChartData
                  .slice(0, touRateSelected ? 1 : 2)
                  .map((pieChartDatum) => {
                    return (
                      <div
                        key={pieChartDatum.label}
                        style={touRateSelected ? { width: "33vw" } : {}}
                      >
                        <PieChart
                          customEmbedConfig={customEmbedConfig} 
                          pieChartData={pieChartDatum}
                        />
                      </div>
                    );
                  })}
              </Stack>
              <Stack
                className="justify-content-center"
                direction="horizontal"
                gap={5}
              >
                {pieChartData.slice(2).map((pieChartDatum) => {
                  return (formData.solarQuantity === 0 && pieChartDatum.label.includes("Self-Generation")) ? 
                  null : (
                    <div key={pieChartDatum.label}>
                      <PieChart
                        customEmbedConfig={customEmbedConfig}
                        pieChartData={pieChartDatum}
                      />
                    </div>
                  );
                })}
              </Stack>
            </div>
          ) : pieChartData.map((pieChartDatum) => {
            return (formData.solarQuantity === 0 && pieChartDatum.label.includes("Self-Generation")) ? 
              null : (
              <Row
                key={pieChartDatum.label}
                style={touRateSelected ? { width: "33vw" } : {}}
              >
                <PieChart
                  customEmbedConfig={customEmbedConfig}
                  pieChartData={pieChartDatum}
                />
              </Row>
            );
          }) :
          (
            <Stack
              direction="horizontal"
              gap={3}
              style={{ direction: "flex", justifyContent: "space-around" }}
            >
              {pieChartData.map((pieChartDatum) => {
                if (
                  (touRateSelected && pieChartDatum.label.includes("Grid Reliance")) ||
                  (formData.solarQuantity === 0 && pieChartDatum.label.includes("Self-Generation"))
                ) {
                  return null;
                } else {
                  return (
                    <div
                      key={pieChartDatum.label}
                      style={touRateSelected ? { width: "289px" } : {}}
                    >
                      <PieChart
                        customEmbedConfig={customEmbedConfig}
                        pieChartData={pieChartDatum}
                      />
                    </div>
                  );
                }
              })}
            </Stack>
          )}
        </Container>
        {!generatingEmailReport && (
          <div id="system-update-buttons">
            {!hideSystemDesignSection && (
              <Container style={{ padding: "0px" }}>
                <Row>
                  {!isMobile && <Col xs={8} />}
                  <Col xs={isMobile ? 12 : 4}>
                    <GeneralButton
                      icon={slidersIcon}
                      text={
                        widgetVisibility.showDesignWidget
                          ? "Hide System Update"
                          : "Update System"
                      }
                      onClick={() => toggleWidget("showDesignWidget")}
                      fullWidth
                      rounded
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {widgetVisibility.showDesignWidget && (
              <Row>
                <SystemDesign
                  refs={refs}
                  customEmbedConfig={customEmbedConfig}
                  formData={formData}
                  setFieldValue={setFieldValue}
                  selectedSolarModule={selectedSolarModule}
                  selectedInverters={selectedInverters}
                  selectedBattery={selectedBattery}
                  rateStructures={rateStructures}
                  pvWattsData={pvWattsData}
                  resultsPage={true}
                  setPVWattsData={setPVWattsData}
                  showEquipment={true}
                  showFullInstructions={false}
                  showButton={false}
                />
              </Row>
            )}
            {!hideEstimatesSection && (
              <Container style={{ padding: "0px" }}>
                <Row>
                  {!isMobile && <Col xs={8} />}
                  <Col xs={isMobile ? 12 : 4}>
                    <GeneralButton
                      icon={slidersIcon}
                      text={
                        widgetVisibility.showSystemCostWidget
                          ? "Hide Estimates"
                          : "Show Estimates"
                      }
                      onClick={() => toggleWidget("showSystemCostWidget")}
                      fullWidth
                      rounded
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {widgetVisibility.showSystemCostWidget && (
              <SystemCost formData={formData} setFieldValue={setFieldValue} />
            )}
            {!hideBillInfoSection && (
              <Container style={{ padding: "0px" }}>
                <Row>
                  {!isMobile && <Col xs={8} />}
                  <Col xs={isMobile ? 12 : 4}>
                    <GeneralButton
                      icon={slidersIcon}
                      text={
                        widgetVisibility.showBillInfoWidget
                          ? "Hide Bill Info"
                          : "Show Bill Info"
                      }
                      onClick={() => toggleWidget("showBillInfoWidget")}
                      fullWidth
                      rounded
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {widgetVisibility.showBillInfoWidget && (
              <BillInfo
                customEmbedConfig={customEmbedConfig}
                formData={formData}
                gridRowData={advancedGridRowData}
                hideRateSelection={hideRateSelectionResultsPage}
                rateStructures={rateStructures}
                setFieldValue={setFieldValue}
              />
            )}
            {!hideKwhUseResultsPage && (
              <Container style={{ padding: "0px" }}>
                <Row>
                  {!isMobile && <Col xs={8} />}
                  <Col xs={isMobile ? 12 : 4}>
                    <GeneralButton
                      icon={slidersIcon}
                      text={
                        widgetVisibility.showKwhUse
                          ? "Hide kWh use"
                          : "Show kWh use"
                      }
                      onClick={() => toggleWidget("showKwhUse")}
                      fullWidth
                      rounded
                    />
                  </Col>
                </Row>
              </Container>
            )}
            {widgetVisibility.showKwhUse && (
              <MonthlyConsumptionInput
                formData={formData}
                setFieldValue={setFieldValue}
                setPVWattsData={setPVWattsData}
                showLabel={false}
                readOnly={false}
              />
            )}
          </div>
        )}
        <div className="padding" />
        {advancedGridRowData && !hidePerformanceChart && (
          <ResultsTable
            gridRef={resultsGridRef}
            label="Projected Performance"
            rowData={advancedGridRowData}
            showWhen={!isMobile}
            showInstructions={false}
          />
        )}
        {!isMobile && !hideSystemDetailsChart && (
          <QuickDesignSystemSummary
            label="System Details"
            refs={refs}
            formData={formData}
            selectedSolarModule={selectedSolarModule}
            selectedInverters={selectedInverters}
            selectedBattery={selectedBattery}
          />
        )}
        {!generatingEmailReport && (
          <div>
            <div className="padding" />
            <GeneralSubmitButton
              variant={widgetVisibility.showEmailWidget ? "secondary" : "warning"}
              className="btn-lg form-control"
              onClick={() => toggleWidget("showEmailWidget")}
              text={
                widgetVisibility.showEmailWidget
                  ? "Hide Email Report Widget"
                  : "Email Report"
              }
              fullWidth
            />
            {widgetVisibility.showEmailWidget && (
              <div>
                <div className="padding" />
                <MailingListSignup
                  refs={refs}
                  formData={formData}
                  selectedSolarModule={selectedSolarModule}
                  selectedInverters={selectedInverters}
                  selectedBattery={selectedBattery}
                  rateStructures={rateStructures}
                  pvWattsData={pvWattsData}
                  setFieldValue={setFieldValue}
                  setGeneratingEmailReport={setGeneratingEmailReport}
                  toggleWidget={toggleWidget}
                  emailReportRef={emailReportRef}
                  signupTermsOfUse={signupTermsOfUse}
                />
                <div className="padding" />
              </div>
            )}
          </div>
        )}
        {(
          <GeneralButton
            variant="warning"
            className="btn-lg form-control"
            onClick={handleDownloadCsv}
            text="Download CSV"
            fullWidth
            rounded
          />
        )}
      </div>
    </div>
  );
}

export default QuickDesignResults;
