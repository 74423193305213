import React, { useCallback, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import useDeviceSize from "hooks/device-size";
import GeneralButton from "components/buttons/general-button.jsx";
import useApi from "hooks/api.js";
import RateSelection from "./rate-selection/rate-selection.jsx";
import MonthlyConsumptionInput from "./monthly-consumption.jsx";
import EquipmentSelection from "./results/equipment-selection.jsx";

function SystemDesign({
  formData,
  setFieldValue,
  setPVWattsData,
  rateStructures,
  showButton,
  showFullInstructions,
  showEquipment,
  resultsPage,
  goToNextStep,
  goToPreviousStep,
  customEmbedConfig
}) {
  const { isMobile } = useDeviceSize();
  const api = useApi();

  const { skipRateSelectionPage, showBackButton } = customEmbedConfig;

  const handleSubmit = useCallback(() => {
    if (!formData.oldRateStructure) {
      setFieldValue("oldRateStructure", rateStructures[0].label);
    }
    if (!formData.newRateStructure) {
      setFieldValue("newRateStructure", formData.oldRateStructure ? formData.oldRateStructure : rateStructures[0].label);
    }

    const { systemLossPercentage, latitude, longitude } = formData;

    api
      .post("/solar-site-data/get-pvwatts-data", {
        systemLossPercentage,
        latitude,
        longitude,
      })
      .then((response) => {
        const responseData = response.data.data;
        setPVWattsData(responseData);
        goToNextStep({ skipFade: skipRateSelectionPage });
      })
      .catch((e) => console.log(e + "\nError in fetch"));
  }, [api, formData, setFieldValue, setPVWattsData, goToNextStep]);

  useEffect(() => {
    if (skipRateSelectionPage) {
      handleSubmit();
    }
  }, []);

  return (
    <Container style={{ padding: isMobile && "0px" }}>
      <Form noValidate>
        <div className="padding" />
        {showEquipment && (
          <EquipmentSelection
            formData={formData}
            setFieldValue={setFieldValue}
            showFullInstructions={showFullInstructions}
            customEmbedConfig={customEmbedConfig}
          />
        )}
        {(!showEquipment || !isMobile) && !resultsPage && (
          <MonthlyConsumptionInput
            formData={formData}
            setFieldValue={setFieldValue}
            showLabel={true}
            readOnly={false}
          />
        )}
        {!resultsPage && (
          <RateSelection
            rateStructures={rateStructures}
            formData={formData}
            setFieldValue={setFieldValue}
            customEmbedConfig={customEmbedConfig}
          />
        )}
        {showButton && (
          <Row
            style={{
              marginTop: "18px",
            }}
          >
            <Col xl={4}>
              {showBackButton && (
                <GeneralButton
                  text="Go Back"
                  variant="secondary"
                  onClick={goToPreviousStep}
                  showWhen={showButton}
                  fullWidth
                  rounded
                />
              )}
            </Col>
            <Col xl={4}/>
            <Col xl={4}>
              <GeneralButton
                text="Estimate Performance"
                variant="warning"
                onClick={handleSubmit}
                showWhen={showButton}
                fullWidth
                rounded
              />
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
}

export default SystemDesign;
