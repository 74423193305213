import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useApi from "hooks/api";
import { useSuccessNotification } from "hooks/success-notification";
import BaseModal from "components/modals/base-modal";
import { REF_KEYS } from "utils/constants.js";
import { getMatchingRefs } from "utils/lib.js";
import { useRefs } from "contexts/refs-context";
import { Col, Form, Row } from "react-bootstrap";
import { EMBED_CONFIG_DEFAULTS, EMBED_CONFIG_OPTIONS } from "./teams-admin-constants";
import { CREATE_OR_UPDATE_TEAM_SCHEMA } from "./teams-admin-validations";
import { createTeam, updateTeam } from "../teams-admin-utils";
import { useUserContext } from "contexts/user-context";
import AddTeamOwnerWidget from "./add-team-owner-widget";

function TeamAdminModal({ onClose, show, team, refetchTeam }) {
  const api = useApi();
  const { notifySuccess } = useSuccessNotification();
  const { refs } = useRefs();
  const { user } = useUserContext();
  const [teamOwners, setTeamOwners] = useState([]);
  const solarOptions = getMatchingRefs(refs, REF_KEYS.BasicSolarOptions);
  const batteryOptions = getMatchingRefs(refs, REF_KEYS.BasicBatteryOptions);

  const defaultValues = team || EMBED_CONFIG_DEFAULTS;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(CREATE_OR_UPDATE_TEAM_SCHEMA),
    mode: "onBlur",
  });

  useEffect(() => {
    reset(team || EMBED_CONFIG_DEFAULTS);
  }, [show, team, reset]);

  const onConfirm = handleSubmit(async (data) => {
    const { email } = user;
    try {
      if (team) {
        await updateTeam(api, data, email, team.teamCode);
      } else {
        await createTeam(api, data, email, teamOwners);
      }
      notifySuccess(`Team ${team ? "updated" : "created"} successfully`);
      refetchTeam();
      onClose();
    } catch (error) {
      console.error(`Error ${team ? "updating" : "creating"} team`, error);
    }
  });

  const handleOwnersAdded = (newOwners) => {
    setTeamOwners(newOwners);
  };

  return (
    <BaseModal
      show={show}
      onHide={onClose}
      onClose={onClose}
      title={`${team ? "Update" : "Create"} Team`}
      confirmButtonText={`${team ? "Update" : "Create"} Team`}
      onConfirm={onConfirm}
      confirmDisabled={!isValid}
      size="lg"
    >
      {show && (
        <Form key={team ? team.id : "create"}>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Team Name</Form.Label>
                <Form.Control {...register("name")} isInvalid={!!errors.name} />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  {...register("description")}
                  isInvalid={!!errors.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {team && (
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="teamCode">
                  <Form.Label>Team Code</Form.Label>
                  <Form.Control
                    {...register("teamCode")}
                    isInvalid={!!errors.teamCode}
                    disabled={!team}
                    />
                  <Form.Control.Feedback type="invalid">
                    {errors.teamCode?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col>
              <h5>Team Owners</h5>
              <AddTeamOwnerWidget updateOwners={handleOwnersAdded} />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <h5>Embed Configuration</h5>
              {EMBED_CONFIG_OPTIONS.map((option) => (
                <Form.Group key={option.name} className="mb-3">
                  <Form.Label>{option.label}</Form.Label>
                  <div>
                    <Controller
                      name={`embedConfig.${option.name}`}
                      control={control}
                      defaultValue={defaultValues.embedConfig[option.name]}
                      render={({ field }) => (
                        <>
                          <Form.Check
                            inline
                            type="radio"
                            id={`${option.name}-false`}
                            label="False"
                            {...field}
                            checked={field.value === false}
                            onChange={() => field.onChange(false)}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            id={`${option.name}-true`}
                            label="True"
                            {...field}
                            checked={field.value === true}
                            onChange={() => field.onChange(true)}
                          />
                        </>
                      )}
                    />
                  </div>
                </Form.Group>
              ))}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="defaultMapAddress">
                <Form.Label>Default Map Address</Form.Label>
                <Form.Control {...register("embedConfig.defaultMapAddress")} isInvalid={!!errors.defaultMapAddress} />
                <Form.Control.Feedback type="invalid">
                  {errors.defaultMapAddress?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="defaultSolarModule">
                <Form.Label>Default Solar Module</Form.Label>
                <Form.Select
                  {...register("embedConfig.defaultSolarModule.value")}
                  isInvalid={!!errors.embedConfig?.defaultSolarModule?.value}
                >
                  {solarOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.embedConfig?.defaultSolarModule?.value?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="defaultSolarModuleQuantity">
                <Form.Label>Default Solar Module Quantity</Form.Label>
                <Form.Control
                  type="number"
                  {...register("embedConfig.defaultSolarModule.quantity")}
                  isInvalid={!!errors.embedConfig?.defaultSolarModule?.quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.embedConfig?.defaultSolarModule?.quantity?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="defaultBattery">
                <Form.Label>Default Battery</Form.Label>
                <Form.Select
                  {...register("embedConfig.defaultBattery.value")}
                  isInvalid={!!errors.embedConfig?.defaultBattery?.value}
                >
                  {batteryOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.embedConfig?.defaultBattery?.value?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="defaultBatteryQuantity">
                <Form.Label>Default Battery Quantity</Form.Label>
                <Form.Control
                  type="number"
                  {...register("embedConfig.defaultBattery.quantity")}
                  isInvalid={!!errors.embedConfig?.defaultBattery?.quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.embedConfig?.defaultBattery?.quantity?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="signupTermsOfUse">
                <Form.Label>Signup Terms of Use Text</Form.Label>
                <Form.Control {...register("embedConfig.signupTermsOfUse")} isInvalid={!!errors.signupTermsOfUse} />
                <Form.Control.Feedback type="invalid">
                  {errors.signupTermsOfUse?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="positivePieChartColorA">
                <Form.Label>Pie Chart Color 1</Form.Label>
                <Form.Control {...register("embedConfig.positivePieChartColorA")} isInvalid={!!errors.positivePieChartColorA} />
                <Form.Control.Feedback type="invalid">
                  {errors.positivePieChartColorA?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="negativePieChartColor">
                <Form.Label>Pie Chart Color 2</Form.Label>
                <Form.Control {...register("embedConfig.negativePieChartColor")} isInvalid={!!errors.negativePieChartColor} />
                <Form.Control.Feedback type="invalid">
                  {errors.negativePieChartColor?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="positivePieChartColorB">
                <Form.Label>Pie Chart Color 3</Form.Label>
                <Form.Control {...register("embedConfig.positivePieChartColorB")} isInvalid={!!errors.positivePieChartColorB} />
                <Form.Control.Feedback type="invalid">
                  {errors.positivePieChartColorB?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </BaseModal>
  );
}

export default TeamAdminModal;
