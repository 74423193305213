//To do: add radio button to switch from electric bill to kWh
import React, { useMemo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { getMatchingRefs, displaySolarPower, displayBatteryCapacity } from 'utils/lib.js';
import DropdownInput from 'components/inputs/dropdown.jsx';
import SliderInput from 'components/inputs/slider.jsx';
import { REF_KEYS } from 'utils/constants.js';
import useDeviceSize from 'hooks/device-size';
import { useRefs } from 'contexts/refs-context';
import SingleCheckbox from 'components/inputs/single-checkbox';
import { NET_METERING_TOOLTIP_TEXT } from '../rate-selection/rate-selection-constants';

function EquipmentSelection({
  formData,
  setFieldValue, 
  showFullInstructions,
  customEmbedConfig
}) {
  const { isMobile } = useDeviceSize();
  const { makeSolarOptional, defaultSolarModule } = customEmbedConfig;
  const { refs } = useRefs();
  const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const dailyConsumptionPerMonth = daysPerMonth.map((days, index) => (formData.siteConsumptionData[index] / days));
  const maxDailyConsumption = Math.max(...dailyConsumptionPerMonth);
  const solarOptions = getMatchingRefs(refs, REF_KEYS.BasicSolarOptions);
  const batteryOptions = getMatchingRefs(refs, REF_KEYS.BasicBatteryOptions);
  const { selectedBattery, selectedSolarModule } = formData;
  const [netMeteringEnabled, setNetMeteringEnabled] = useState(formData.netMeteringEnabled || false);
  const [gridChargeDisabled, setGridChargeDisabled] = useState(formData.gridChargeDisabled || false);

  const solarPowerDisplay = useMemo(
    () => displaySolarPower(formData.solarQuantity,selectedSolarModule),
    [formData.solarQuantity, selectedSolarModule]
  );
  const batteryCapacityDisplay = useMemo(
    () => displayBatteryCapacity(formData, selectedBattery),
    [formData.batteryQuantity, formData.siteConsumptionData, selectedBattery, maxDailyConsumption]
  );

  const maxSolar = 96;
  const maxBatteries = 20;

  const dropdownColWidth = isMobile ? 0 : 3;
  const sliderColWidth = 12 - dropdownColWidth;

  const baseStyle = {
    marginTop: '11px',
    marginBottom: '22px'
  }

  const [useSolar, setUseSolar] = useState(formData.solarQuantity === 0 ? false : true);
  const handleUseSolarToggle = (newValue) => {
    setUseSolar(newValue);
    setFieldValue("solarQuantity",newValue ? defaultSolarModule.quantity : 0);
  }
  return (
    <Container>
      <div className="padding" />
      {showFullInstructions && !makeSolarOptional ? (
        <Row>
          <div>
            <i className="instructions">Login to design with real product.</i>
          </div>
        </Row>
      ) : (
        <div />
      )}
      <Row>
        <DropdownInput
          style={baseStyle}
          htmlName="battery-size"
          displayName="Battery Size"
          fieldName="battery"
          tooltip="Some batteries have site requirements - e.g. must be located inside."
          value={formData.battery}
          options={batteryOptions}
          formData={formData}
          setFieldValue={setFieldValue}
          colWidth={dropdownColWidth}
          showWhen={!isMobile}
          readOnly={makeSolarOptional}
        />
        <SliderInput
          style={baseStyle}
          htmlName="battery-slider"
          displayName="Total Battery Capacity"
          fieldName={"batteryQuantity"}
          value={formData.batteryQuantity}
          max={maxBatteries}
          readOnlyTooltip=""
          sliderLabelSingle="battery"
          sliderLabelPlural="batteries"
          displayLabel={batteryCapacityDisplay}
          setFieldValue={setFieldValue}
          colWidth={sliderColWidth}
          isMobile={isMobile}
        />
      </Row>
      {
        useSolar && (
          <Row>
            <DropdownInput
              style={baseStyle}
              displayName="Solar Module Size"
              htmlName="solar-module-size"
              fieldName="solarModule"
              tooltip="Solar modules come in a wide range of power ratings. Consider selecting a more powerful model if your home lacks south-facing roof space."
              value={formData.solarModule}
              options={solarOptions}
              formData={formData}
              setFieldValue={setFieldValue}
              colWidth={dropdownColWidth}
              showWhen={!isMobile}
            />
            <SliderInput
              style={baseStyle}
              htmlName="solar-slider"
              displayName="Total Solar Power"
              fieldName={"solarQuantity"}
              value={formData.solarQuantity}
              max={maxSolar}
              sliderLabelSingle="module"
              sliderLabelPlural="modules"
              displayLabel={solarPowerDisplay}
              setFieldValue={setFieldValue}
              colWidth={sliderColWidth}
              isMobile={isMobile}
            />
          </Row>
        )
      }
      <Row>
        <SingleCheckbox
          id="grid-charge-disable-checkbox"
          label="Disable Battery Charge from Grid"
          checked={gridChargeDisabled}
          onChange={(newValue) => {
            setGridChargeDisabled(newValue);
            setFieldValue("gridChargeDisabled", newValue);
          }}
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
        />
      </Row>
      <Row>
        <SingleCheckbox
          id="net-metering-checkbox"
          label="Use Net Metering Rates"
          checked={netMeteringEnabled}
          onChange={(newValue) => {
            setNetMeteringEnabled(newValue);
            setFieldValue("netMeteringEnabled", newValue);
          }}
          style={{
            display: "flex",
            justifyContent: isMobile ? "center" : "flex-end",
          }}
          tooltip={NET_METERING_TOOLTIP_TEXT}
        />
      </Row>
      {
        makeSolarOptional && (
          <Row>
            <SingleCheckbox
              id="add-solar-checkbox"
              label="System Includes Solar"
              checked={useSolar}
              onChange={(newValue) => handleUseSolarToggle(newValue)}
              tooltip="Select this option to add solar equipment to your design"
              style={{
                display: "flex",
                justifyContent: isMobile ? "center" : "flex-end",
              }}
            />
          </Row>
        )
      }
    </Container>
  );
}

export default EquipmentSelection;