
import { useEffect, useRef, useState } from 'react';
import { defaultFreeDesign } from 'components/objects/defaults';
import useApi from './api';
import { useParams } from 'react-router-dom';
import { getMatchingRefs, isEmpty } from 'utils/lib';
import { REF_KEYS } from 'utils/constants';
import { useRefs } from 'contexts/refs-context';

const useQuickDesigner = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [formData, setFormData] = useState(defaultFreeDesign);
  const [rateStructures, setRateStructures] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [showComponent, setShowComponent] = useState(true);
  const [pvWattsData, setPVWattsData] = useState("");
  const [customEmbedConfig, setCustomEmbedConfig] = useState({});
  const resultsGridRef = useRef();
  const { teamCode } = useParams();
  const api = useApi();
  const { refs } = useRefs();

  const setFieldValue = (keyOrObject, value) => {
    if (typeof keyOrObject === "object") {
      setFormData((existingValues) => ({
        ...existingValues,
        ...keyOrObject,
      }));
    } else {
      setFormData((existingValues) => ({
        ...existingValues,
        [keyOrObject]: value,
      }));
    }
  };

  useEffect(() => {
    const selectedSolarModule = refs.find(
      (ref) => ref.id === formData.solarModule
    );

    const selectedInverters = refs.filter(
      (inverter) => formData.inverterData[0].id === inverter.id
    );
    const selectedBattery = refs.find((ref) => ref.id === formData.battery);
    setFieldValue({
      selectedBattery: selectedBattery,
      selectedInverters: selectedInverters,
      selectedSolarModule: selectedSolarModule,
    });
  }, [refs, formData.battery, formData.inverterData, formData.solarModule]);

  useEffect(() => {
    const getRefId = (refCategory, value) => {
      const refSubset = getMatchingRefs(refs, refCategory);
      return refSubset.find((ref) => ref.value === value)?.id; 
    }
    
    const fetchEmbedConfigAndSetDefaults = async () => {
      try {
        const response = await api.get(
          `team/embedConfig/${teamCode}`
        );
        const customEmbedConfig = response.data.data;

        setCustomEmbedConfig(customEmbedConfig);

        const {
          defaultBattery,
          defaultInverters,
          defaultSolarModule,
          defaultMapAddress,
          makeSolarOptional
        } = customEmbedConfig;

        setFormData((existingValues) => ({
          ...existingValues,
          zipCode: defaultMapAddress,
          batteryQuantity: defaultBattery.quantity,
          battery: getRefId(REF_KEYS.BasicBatteryOptions, defaultBattery.value),
          solarQuantity: makeSolarOptional ? 0 : defaultSolarModule.quantity,
          solarModule: getRefId(REF_KEYS.BasicSolarOptions, defaultSolarModule.value),
          inverterData: [
            {
              id: getRefId(REF_KEYS.BasicInverterOptions, defaultInverters[0].value),
              quantity: defaultInverters[0].quantity
            }
          ],
          teamCode: teamCode,
        }));
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
        setIsLoaded(true);
      }
    };

    if (teamCode && isEmpty(customEmbedConfig)) {
      fetchEmbedConfigAndSetDefaults();
    }
    else {
      setIsLoaded(true)
    }
  }, []);

  const goToNextStep = ({ skipFade = false } = {}) => {
    setShowComponent(false);

    setTimeout(() => setCurrentStep((prevStep) => prevStep + 1), skipFade ? 0 : 300);
  };

  const goToPreviousStep = ({ skipFade = true } = {}) => {
    setShowComponent(false);

    setTimeout(() => setCurrentStep((prevStep) => prevStep - 1), skipFade ? 0 : 300);
  };

  useEffect(() => {
    setShowComponent(true);
  }, [currentStep]);

  return {
    setFieldValue,
    formData,
    setFormData,
    pvWattsData,
    resultsGridRef,
    currentStep,
    goToNextStep,
    goToPreviousStep,
    rateStructures,
    setRateStructures,
    showComponent,
    setPVWattsData,
    teamCode,
    customEmbedConfig,
    isLoaded
  }
}

export default useQuickDesigner;
