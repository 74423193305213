import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import useInitialization from "hooks/initialization";
import LoadingSpinner from "components/widgets/loading-spinner";
import UnderConstruction from "pages/logged-out/under-construction";
import { useUserContext } from "contexts/user-context";
import { useRefs } from "contexts/refs-context";
import AppNavBar from "components/widgets/nav-bar/nav-bar";
import AdminNavBar from "pages/admin/admin-nav-bar";
import LoggedInApp from "pages/logged-in/logged-in";
import LoggedOutApp from "pages/logged-out/logged-out";
import UnauthorizedPage from "pages/logged-in/unauthorized";
import QuickDesignerPage from "pages/quick-designer/quick-designer";
import { useErrorNotification } from "hooks/error-notification";
import { useSuccessNotification } from "hooks/success-notification";
import AlertBanner from "components/widgets/alert-banner";
import useDeviceSize from "hooks/device-size";
import { isSuperAdmin } from "pages/logged-in/home/logged-in-home-utils";
import { getUserHomeRoute } from "pages/logged-in/logged-in-utils";

const NavBarWrapper = () => {
  const location = useLocation();
  const { user } = useUserContext();

  if (location.pathname.startsWith("/embeds/")) {
    return null;
  }

  return isSuperAdmin(user) ? <AdminNavBar /> : <AppNavBar />;
};

function App() {
  const { error, clearError } = useErrorNotification();
  const { successMessage, clearSuccessMessage } = useSuccessNotification();
  const { isLoading } = useInitialization();
  const {isMobile} = useDeviceSize();
  const { user } = useUserContext();
  const { refs } = useRefs();
  const loggedIn = !!user;
  const location = useLocation();
  const userHomeRoute = getUserHomeRoute(user);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!refs || refs.length < 1) {
    return <UnderConstruction />;
  }

  if (location.pathname === "/" || location.pathname === "/home") {
    return <Navigate to={userHomeRoute} replace />;
  }

  return (
    <div className="App">
      <NavBarWrapper />
      {error && (
        <AlertBanner message={error} type="danger" onClose={clearError} />
      )}
      {successMessage && (
        <AlertBanner
          message={successMessage}
          type="success"
          onClose={clearSuccessMessage}
        />
      )}
      <div className={isMobile ? "px-2" : "SystemDesigner"}>
        <div
          className={isMobile ? "transparent mt-2" : "transparent"}
          style={{
            marginTop: "5vh",
            marginBottom: "5vh",
            borderRadius: "10px",
          }}
        ></div>
        <Routes>
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/embeds/:teamCode" element={<QuickDesignerPage />} />
          <Route
            path="/*"
            element={loggedIn ? <LoggedInApp /> : <LoggedOutApp />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
