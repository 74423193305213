import React from 'react';
import { VictoryPie, VictoryLabel } from 'victory';
import { toHTMLString, numberWithCommas } from 'utils/lib.js';

function PieChart({customEmbedConfig, pieChartData}) {
  const label = pieChartData.label;
  const data = pieChartData.data;
  const id = toHTMLString(label) + '-pie-chart';
  const negativeColor = customEmbedConfig.negativePieChartColor ? customEmbedConfig.negativePieChartColor : 'rgba(239,179,7,1)';
  const positiveColorA = customEmbedConfig.positivePieChartColorA ? customEmbedConfig.positivePieChartColorA : 'rgba(67,253,242,1)';
  const positiveColorB = 'rgba(0,162,232,1)';
  const colorScale = data.length > 2 ? [negativeColor,positiveColorB, positiveColorA] : [negativeColor,positiveColorA,positiveColorB];
  const labelComponent = (
    <VictoryLabel
      style={{
        fontSize: '18px'
      }}/>
  );
  return (
    <div>
      <label
        style={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '24px',
          fontWeight: 'bold'
        }}
        htmlFor={id}> {label} </label>
      <VictoryPie
        id={id}
        data={data}
        padding={{ top: 11, bottom: 0 }}
        padAngle={5}
        innerRadius={20}
        labels={({ datum }) => [`${datum.x}`,`${pieChartData.currency ? pieChartData.currency : ''}${numberWithCommas(datum.y)}${pieChartData.unit ? pieChartData.unit : ''}`]}
        labelComponent={labelComponent}
        labelRadius={105}
        colorScale={colorScale}
        style={{
          labels: {
            fill: "black",
            fontSize: 16,
            textAnchor: "middle",
            verticalAnchor: "middle",
          }
        }}/>
    </div>
  );
}

export default PieChart;